<template>
  <div v-loading="loading" class="table-area">
    <div class="table-top">
    <el-row>
      <div class="trading-wrapper">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Trading Clients</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div v-if="getRoleSlug == 'admin'" class="search-wrapper">
        </div>
        <div v-else-if="getRoleSlug == 'superadmin'" class="search-wrapper">
          <!-- <el-input
            placeholder="Search"
            v-model="listQuery.search"
            class="input-with-select"
            style="width: 500px"
          >
            <el-select
              slot="prepend"
              v-model="listQuery.search_type"
              placeholder="select"
              clearable
              filterable
              style="width: 150px"
              @change="handleSelectSearchType()"
            >
              <el-option
                v-for="item in listAdmins"
                :key="item.master"
                :label="item.name"
                :value="item.master"
              />
            </el-select>
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="sendChildFormData()"
            ></el-button>
          </el-input> -->
        </div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <el-button v-if="getRoleSlug == 'admin'" type="primary" @click="handleCreate()">Add</el-button> -->
          <!-- <el-button v-if="getRoleSlug == 'superadmin'" type="primary" @click="handleCreateSA()">Add</el-button> -->
          <!-- <el-button v-if="getRoleSlug == 'broker'" type="primary" @click="brokerHandleCreate()">Add</el-button> -->
          <el-button v-if="getRoleSlug == 'superadmin'" type="primary" @click="handleDownload()">Download</el-button>
          

        </div>
      </el-col>
    </div>
      <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
        <div class="trading-client">
          <el-form
            :model="formData"
            ref="refForm"
            label-position="top"
            :status-icon="true"
          >
            <el-row :gutter="10">
              <!-- <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.userId"
                    label="Id"
                    prop="userId"
                    :label-width="formLabelWidth"
                  >
                    <el-input v-model="listQuery.userId" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col> -->
              <el-col :xs="24" :sm="3" :md="3" :lg="3" :xl="3">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.name"
                    label="Name"
                    prop="name"
                    :label-width="formLabelWidth"
                  >
                    <el-input v-model="listQuery.name" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.username"
                    label="Username"
                    prop="username"
                    :label-width="formLabelWidth"
                  >
                    <el-input v-model="listQuery.username" autocomplete="off"></el-input>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.demo_account"
                    label="Demo account ?"
                    prop="demo_account"
                    :label-width="formLabelWidth"
                  >
                     <el-select v-model="listQuery.demo_account" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Yes" value="true" ></el-option>
                        <el-option label="No" value="false" ></el-option>
                      </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.status"
                    label="Account status"
                    prop="status"
                    :label-width="formLabelWidth"
                  >
                     <el-select v-model="listQuery.status" placeholder="Select">
                        <el-option label="All" value="" ></el-option>
                        <el-option label="Active" value="active" ></el-option>
                        <el-option label="Inactive" value="inactive" ></el-option>
                      </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="4" :md="4" :lg="4" :xl="4">
                <div class="search-wrapper">
                  <el-form-item
                    :error="formErrors.status"
                    label="Master"
                    prop="adminId"
                    :label-width="formLabelWidth"
                  >
                  <el-select
                    v-model="listQuery.search_type"
                    placeholder="select"
                  >
                  <el-option
                      v-for="item in listAdmins"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    />
                  </el-select>
                  </el-form-item>
                </div>
              </el-col>
              <el-col :xs="24" :sm="5" :md="5" :lg="5" :xl="5">
                <div class="btn-wrapper">
                  <el-form-item label="">
                    <div style="height: 40px;"></div>
                    <el-button type="primary" @click="handleSearch()"
                      >Search</el-button
                    >
                    <el-button type="primary" @click="handleReset()"
                      >Reset</el-button
                    >
                  </el-form-item>
                </div>
              </el-col>

            </el-row>
          </el-form>
        </div>
      </el-col>
    </el-row>
    </div>
    <Table
      tableKey="tradingClients"
      :tableConfig="config"
      :tableData="listData"
      :tableQuery="listQuery"
      :tableActionVisibility="false"
      :tableActionViewVisibility="true"
      :tableActionDollarVisibility="true"
      :tableActionShowVisibility="true"
      :tableActionEditVisibility="false"
      :tableActionDeleteVisibility="true"
      :tableActionLogoutVisibility="tableActionLogoutVisibility"
      :tableLoadingStatus.sync="loading"
      :tablePagination="tablePagination"
      @pagination="handlePagination()"
      @edit="handleEdit($event)"
      @view="handleViewDetails($event)"
      @dollar="handleDollar($event)"
      @delete="handleDelete($event)"
    />

    <AddEditDialog
      :dialog-visible.sync="dialogVisible"
      :dialog-type="dialogType"
      :form-data="formData"
      :form-errors.sync="formErrors"
      @getChildFormData="handleChildFormData($event)"
      @getChildFormDebitData="handleChildFormDebitData($event)"
      @childClose="handleChildClose()"
    />
  </div>
</template>

<script>
import Table from "@/components/Table/Table.vue";
import AddEditDialog from "@/views/trader-funds/components/AddEditDialog.vue";
import { fetchList, destroy, downloadClientPLList } from "@/api/trading-client";
import getRoleSlug from "@/store/app.js";
// import { _ } from "vue-underscore";
import { mapGetters } from "vuex";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { store, debit } from "@/api/trader-fund";

export default {
  name: "ListTradingClients",
  components: {
    Table,
    AddEditDialog,
  },
  data() {
    return {
      loading: false,
      search: "",
      listDataTemp: null,
      tableActionLogoutVisibility:false,
      config: [
        // {
        //   label: "ID",
        //   prop: "userid",
        //   width: "",
        //   className: "blueFont",
        // },
        {
          label: "Name",
          prop: "name",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Username",
          prop: "username",
          width: "200",
          popover: false,
          className: "blueFont",
        },
        {
          label: "Master",
          prop: "master_name",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Ledger Balance",
          prop: "ledger_balance",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Gross PL",
          prop: "gross_pl",
          width: "120",
          className: "blueFont",
        },
        {
          label: "Brokerage",
          prop: "brokerage",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Net PL",
          prop: "net_pl",
          width: "150",
          className: "blueFont",
        },
        {
          label: "Demo Account?",
          prop: "demo_account",
          width: "180",
          className: "blueFont",
        },
        {
          label: "Status",
          prop: "status",
          width: "100",
          className: "blueFont",
        },
      ],
      listAdmins: {},
      listQuery: {
        page: 1,
        limit: 20,
        search: "",
        search_type: "",
        searchJoin: "or",
        orderBy: "created_at",
        sortedBy: "desc",
      },
      listData: {
        data: {
          item: [],
        },
      },
      tablePagination: {},
      permission: false,
      dialogVisible: false,
      dialogType: "create",
      formData: {},
      formErrors: [],
      searchFormData: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
    ...mapGetters(["getRoleSlug", "userInfo"]),
  },
  created() {
    this.getList();
    this.getAdminList();
  },
  methods: {
    handlePagination() {
      this.getList();
    },
    handleReset() {
      this.listQuery = {}
      this.getList();
    },
    handleCreateSA() {
        return this.$router.push(
          "/super-admin/trading-clients/trading-clients-add"
        );
    },
    handleCreate() {
      if (this.userInfo.allowedclient > this.userInfo.customerCount) {
        this.permission = true;
      }
      if (this.permission) {
        return this.$router.push(
          "/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-add"
        );
      } else {
        // this.$message.warning('Limit Cross');
        this.$notify.error({
          title: "Error",
          message: "Limit Cross",
        });
      }
    },
    brokerHandleCreate() {
        return this.$router.push(
          "/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-add"
        );
    },
    handleEdit(id) {
      if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push("/super-admin/trading-clients/trading-clients-edit/" + id );
      } else {
        return this.$router.push("/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-edit/" + id);
      }
      // return this.$router.push(
      //   "/" + getRoleSlug.state.roleSlug + "/trading-clients-edit/" + id
      // );
    },
    handleViewDetails(data) {
      console.log(data);
      if(getRoleSlug.state.roleSlug == 'superadmin') {
        return this.$router.push("/super-admin/trading-clients/trading-clients-view/" +data.id );
      } else {
        return this.$router.push("/" + getRoleSlug.state.roleSlug + "/trading-clients/trading-clients-view/" +data.id);
      }
      // return this.$router.push(
      //   "/" + getRoleSlug.state.roleSlug + "/trading-clients-view/" + data.id
      // );
    },
    // //////////////////
    // HANDLE SEARCH
    // //////////////////
    // handleSearch() {
    //   this.listData.data.item = this.listDataTemp;
    //   let s = this.search;
    //   s = s.toLowerCase();
    //   this.listData.data.item = _.filter(this.listData.data.item, function (d) {
    //     return d["name"] && d["name"].toLowerCase().includes(s);
    //   });
    // },
    handleSelectSearchType() {
      this.handleSearch();
    },
    handleSearch() {
      this.listQuery.page = 1;
      // this.listQuery.search_type = this.listQuery.search_type;
      this.getList();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getList() {
      this.loading = true;
      fetchList(this.listQuery).then((response) => {
        this.listDataTemp = this.listData.data.item = response.data.data.data;
        this.tablePagination = response.data.data.meta;
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins = response.data.data;
      });
    },
    handleDollar(data) {
      console.log(data);
      this.formData.user_id = data.id;
      this.formErrors = [];
      this.dialogVisible = true;
      this.dialogType = "create";
    },
    handleChildClose() {
      this.dialogVisible = false;
    },
    // //////////////////
    // EMIT METHODS
    // //////////////////
    handleChildFormData(data) {
      this.handleStore(data);
    },
    handleStore(data) {
      this.loading = true;
      console.log(data);
      this.dialogVisible = false;
      this.getList();
      store(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
     // //////////////////////
    // EMIT METHODS Debit
    // /////////////////////
    handleChildFormDebitData(data) {
      this.handleDebit(data);
    },
    handleDebit(data) {
      this.loading = true;
      console.log("gogogog   rehhh  ",data);
      this.dialogVisible = false;
      this.getList();
      debit(data)
        .then((response) => {
          if (response.data.success === true) {
            this.dialogVisible = false;
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          } else {
            this.flashError(response.data.message);
            this.formErrors = this.validationErrors(response.data.message);
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // //////////////////
    // DELETE
    // //////////////////
    handleDelete(data) {
      console.log(data.id);
      this.$confirm(
        "This will permanently delete record. Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.loading = true;
        destroy(data.id).then((response) => {
          if (response.data.success === true) {
            this.flashSuccess(response.data.message);
            this.getList();
            this.loading = false;
          }
        });
      });
    },
    handleDownload() {
      downloadClientPLList(this.listQuery).then((response)=> {
        console.log(response.data.data)
        let res = response.data.data
        this.download(res.fileName,res.path)
      })
      .catch((err) => {
        console.log(err);
      });
    },
    download(filename, url) {
          var xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          // xhr.setRequestHeader('Content-type','application/x-www-form-urlencoded');
          xhr.onload = () => {
              var blob = xhr.response;
              var downloaded = document.createElement('a');
              var downloadedurl = window.URL.createObjectURL(blob);
              downloaded.href = downloadedurl;
              downloaded.download = filename;
              document.body.append(downloaded);
              downloaded.click();
              downloaded.remove();
              window.URL.revokeObjectURL(downloadedurl);
          };
          xhr.open('GET', url);
          xhr.send();
    },
    // //////////////////
    // EDIT
    // //////////////////
    // handleEdit(id) {
    //   this.fetchData(id);
    // },
    // fetchData(id) {
    //   this.loading = true;
    //   edit(id)
    //     .then((response) => {
    //       this.formData = response.data.data;
    //       if (response.data.success === true) {
    //         this.formData = response.data.data;
    //         this.loading = false;
    //         this.dialogType = "edit";
    //         this.dialogVisible = true;
    //       } else {
    //         this.$message.warning(response.data.message);
    //       }
    //       this.loading = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    //   this.dialogFormVisible = true;
    // },
  },
};
</script>
